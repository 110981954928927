// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column;

  /* Set the height and width of the parent container */
  height: 100vh; /* 100% of the viewport height */
  width: 100vw; /* 100% of the viewport width */
  background-color: #000;
  color: #fff;
}

textarea {
  width: 800px;
  height: 200px;
  font-size: 2em;
}

button {
  padding: 20px;
  font-size: 2em;
}

h1 {
  font-size: 14em;
  letter-spacing: 6px;
}

.Player {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  -webkit-user-select: none;
          user-select: none;
}

.Preview {
  font-size: 2em;
  height: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,sBAAsB;;EAEtB,qDAAqD;EACrD,aAAa,EAAE,gCAAgC;EAC/C,YAAY,EAAE,+BAA+B;EAC7C,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,sBAAsB;EACtB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,aAAa;AACf","sourcesContent":[".App {\n  display: flex;\n  justify-content: center; /* Center horizontally */\n  align-items: center; /* Center vertically */\n  flex-direction: column;\n\n  /* Set the height and width of the parent container */\n  height: 100vh; /* 100% of the viewport height */\n  width: 100vw; /* 100% of the viewport width */\n  background-color: #000;\n  color: #fff;\n}\n\ntextarea {\n  width: 800px;\n  height: 200px;\n  font-size: 2em;\n}\n\nbutton {\n  padding: 20px;\n  font-size: 2em;\n}\n\nh1 {\n  font-size: 14em;\n  letter-spacing: 6px;\n}\n\n.Player {\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  flex-direction: column;\n  user-select: none;\n}\n\n.Preview {\n  font-size: 2em;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
